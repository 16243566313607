<template>
  <div class="">
    <b-card class="">
      <div class="mb-1 d-flex justify-content-between">
        <strong>
          Staff Members
        </strong>
      </div>
      <b-table
        small
        :fields="fields"
        :items="members"
        responsive="sm"
      >
        <template #cell(action)="data">
          <!-- <hwa-button
            :icon="getRole(data.item.roles)==='Manager'?'UserCheckIcon':'UserXIcon'"
            :icon-only="true"
            :variant="getRole(data.item.roles)==='Manager'?'flat-success':'flat-danger'"
            @onClick="assignManagerRole(data.item,getRole(data.item.roles)==='Manager')"
          /> -->
          <b-form-checkbox
            v-b-tooltip.hover.right="getRole(data.item.roles)==='Manager'? 'switch user role to staff' : 'switch user role to manager'"
            class="custom-control-success"
            name="check-button"
            switch
            :checked="getRole(data.item.roles)==='Manager'? true :false"
            @change="assignManagerRole(data.item,getRole(data.item.roles)==='Manager')"
          >
            <span class="switch-icon-left">
              <feather-icon icon="UserCheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="UserXIcon" />
            </span>
          </b-form-checkbox>
        </template>
        <template #cell(roles)="data">
          <b-badge :variant="getRole(data.item.roles)==='Manager'?'light-success':'light-warning'">
            {{ getRole(data.item.roles) }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
    <validation-observer
      ref="termForm"
      v-slot="{invalid}"
    >
      <hwa-modal
        title="Assign Role"
        :show="roleModalOpened"
        @onClose="closeRoleModal()"
      >
        <div style="margin-bottom: 100px">
          <hwa-multi-select
            v-model="selectedRoles"
            name="role"
            rules="required"
            placeholder="Select Role"
            label-name="name"
            :options="roles"
          />
        </div>
        <template slot="modal-actions">
          <b-row class="float-right">
            <hwa-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeRoleModal()"
            />
            <hwa-button
              label="Assign"
              :disabled="invalid"
              :loading="roleLoading"
              loading-text="Loading..."
              @onClick="assignManagerRole()"
            />
          </b-row>
        </template>
      </hwa-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BTable, BCard, BRow, BBadge, BFormCheckbox,
  VBTooltip,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import { ValidationObserver } from 'vee-validate'
import HwaModal from '@/layouts/components/HwaModal.vue'
import confirm from '@/mixins/confirm'
import HwaMultiSelect from '@/layouts/components/HwaMultiSelect.vue'

export default {
  components: {
    HwaMultiSelect,
    HwaModal,
    HwaButton,
    BTable,
    BCard,
    BRow,
    ValidationObserver,
    BBadge,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      fields: [{key:'full_name',label:"Full Name",sortable:true}, {key:'email',label:'Email',sortable:true}, {key:'roles',label:"Roles",sortable:true},"action"],
      members: [],
      roles: [],
      selectedRoles: [],
      member_id: null,
      roleLoading: false,
      roleModalOpened: false,
    }
  },
  mounted() {
    this.getMembers()
    this.getRoles()
  },
  methods: {
    getRole(roles) {
      let role
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].name === 'Manager') {
          role = 'Manager'
          break
        }
      }
      return role || roles[0].name
    },
    getMembers() {
      this.$http.get('/api/admin/staff-members')
        .then(res => {
          this.members = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve members')
        })
    },
    getRoles() {
      this.$http.get('/api/admin/roles')
        .then(res => {
          this.roles = res.data
        })
        .catch(err => {
          this.showError('Failed to retrieve roles')
        })
    },
    assignManagerRole(member, isManager) {
      // if (this.selectedRoles.length > 0 && this.member_id) {
      this.roleLoading = true
      this.$http.post('/api/admin/assign-role', { manager: !isManager, staff_id: member.id })
        .then(res => {
          this.roleLoading = false
          this.showSuccess('Assigned successfully')
          this.members = this.members.map(m => {
            if (m.id === member.id) {
              return res.data
            }
            return m
          })
        })
        .catch(err => {
          this.roleLoading = false
          this.showError('Failed to assign role')
        })
      // } else {
      //   this.showError('Select Role')
      // }
    },
    closeRoleModal() {
      this.roleModalOpened = false
    },
    showRoleModal(member) {
      this.member_id = member.id
      this.selectedRoles = member.roles
      this.roleModalOpened = true
    },
  },

}
</script>
